import React from "react" 
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import "./styles.scss"

import { AboutAnimation } from "src/components/atoms/animations"

const Philosphy = () => {
  const data = useStaticQuery(graphql`
    query {
      sphere: file(relativePath: { eq: "sphere.png" }) {
        childImageSharp {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bg: file(relativePath: { eq: "about-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1003) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return <section id="philosophy" className="philosophy">
    <div className="content-wrap">
      <div className="animation">
        <Img className="bg" fluid={data.bg.childImageSharp.fluid} />
        <Img className="sphere" fluid={data.sphere.childImageSharp.fluid} />
        <AboutAnimation />
      </div>
      <div className="text-content">
        <h3>
          Our<br />
          Philosophy
        </h3>
        <h4>
          We are a team of technologists and management consultants. Our team has knowledge and experience of working with the latest technologies and we bring modern approaches which assist our clients better understand the vast troves of untapped data that they have within their ecosystem.
          <br /><br />
          We leverage our AI and Machine Learning (ML) algorithms expertise to automate and enable a highly transparent, collaborative environment that fosters a mutually supportive and exploratory culture, ultimately providing our clients more efficiency and information at their fingertips.
        </h4>
      </div>
    </div>
  </section>
}

export default Philosphy