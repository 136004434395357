import React from "react" 
import { Link, ScrollIcon } from "src/components/atoms"
import "./styles.scss"

const Header = () => {
  return <header className="about-header">
    <div className="content-wrap">
      <h1>
        About<br />
        RDW Technologies
      </h1>
      <h2>
        Founded in 2018, RDW Technologies is transforming the way federal agencies think about their digital practices. We use our world class technical expertise to harness our client’s data, streamline business processes through better insights and automation, and sophisticated, easy-to-use solutions and services.
      </h2>

      <Link to="#!/philosphy">
        <ScrollIcon />
      </Link>
    </div>
  </header>
}

export default Header 