import * as React from "react"
import { Layout, Seo } from "src/components/molecules"
import { Navigation, MobileNavigation, Footer } from "src/components/organisms"
import { Header, Philosophy, JoinOurTeam, Team } from "src/components/templates/about"



const AboutPage = () => {
  return (
    <Layout>
      <Seo title="About" description="RDW Technologies helps federal agencies revolutionize the digital federal landscape through our easy-to-use, data-driven solutions and services that are engineered to fit the needs of every government  agency." />
      <Navigation />
      
      
      <div className="content">
        <Header />
        <Philosophy />
      </div>

      <Footer />
      <MobileNavigation />
    </Layout>
  )
}

export default AboutPage
